import ReactDOM from 'react-dom';
import React from 'react';
import {FetchCall} from "../helpers/http_helper";
import {Toaster,toast} from "react-hot-toast";

class Admins extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            admins: [],
            isAdmin: false,
            authId: null
        }

        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        FetchCall('/getAdmins', "POST", "all", this.getAdminsSuccess.bind(this));
    }

    getAdminsSuccess(result){
        this.setState({admins: result.admins, isAdmin: result.isAdmin, authId: result.authId});
    }

    handleChange(e, id, role){
        var data = {
            id: id,
            role: role == 1 ? 0 : 1
        }
        FetchCall('/setAdmins', "POST", {data}, this.getAdminsSuccess.bind(this));
        toast.success('Modificare salvata',
            {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
    }

    render() {
        return (
            <div className="admins-container">
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />
                Pentru a adauga pe cineva cu rol de admin, acesta trebuie sa fie selectat.
                { this.state.admins.map((admin, i) => {
                    return(
                        <div key={ admin.id } className="admin-row">
                            <div>{ (i+1) + "." }</div>
                            <img src={admin.profile_photo_url} />
                            <div className="admin-label">{ admin.name }</div>
                            <div className="admin-label">{ admin.email }</div>
                            { this.state.authId != admin.id && this.state.isAdmin && <label className="switch">
                                <input type="checkbox" checked={ admin.role == 1} onChange={ (e) => this.state.isAdmin ? this.handleChange(e, admin.id, admin.role) : null } />
                                    <span className="slider round"></span>
                            </label>}
                        </div>
                    );
                }) }
            </div>
        );
    }
}

export default Admins;

if (document.getElementById('admins-react')) {
    ReactDOM.render(<Admins />, document.getElementById('admins-react'));
}
