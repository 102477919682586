require('./bootstrap');

require('./components/candidates');
require('./components/admins');

// import Alpine from 'alpinejs';
//
// window.Alpine = Alpine;
//
// Alpine.start();
