import React from 'react';
import ReactModal from 'react-modal';
import Select from 'react-select'
import moment from 'moment'

const customStylesSelect = {
    singleValue:{
        color:'#08699B',
    },
}

const customStyles = {
    content: {
        width: '80%',
        height: '80%',
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

class CustomModal extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: this.props.showModal ? this.props.showModal : false,
            id: null,
            firstname: "",
            lastname: "",
            email: "",
            high_school: "",
            profile: "",
            interes_country: "",
            specialization: "",
            school_average: "",
            extracurricular_activities: "",
            city: "",
            year_of_admission: "",
            how_find_us: "",
            // class: "",
            contact_date: "",
            meet: "",
            notes: "",
            phone: "",
            parent_name: "",
            parent_email: "",
            parent_phone: "",
            status: "",
            follow_up_1: "",
            mentiuni_f1: "",
            follow_up_2: "",
            mentiuni_f2: "",
            follow_up_3: "",
            mentiuni_f3: "",
            event: "",
            meet_date: "",
            service_pack: "",
        }

        this.handleChange = this.handleChange.bind(this);
        this.handleStatusChange = this.handleStatusChange.bind(this);
        this.handleServicePackChange = this.handleServicePackChange.bind(this);
        this.handleHowFindUsChange = this.handleHowFindUsChange.bind(this);
        this.handleYearOfAdmissionChange = this.handleYearOfAdmissionChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if(this.props.showModal != this.state.showModal){
            this.setState({showModal: this.props.showModal});
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            id: props.candidateEdited && props.candidateEdited.id ? props.candidateEdited.id : null,
            firstname: props.candidateEdited && props.candidateEdited.firstname ? props.candidateEdited.firstname : "",
            lastname: props.candidateEdited && props.candidateEdited.lastname ? props.candidateEdited.lastname : "",
            email: props.candidateEdited && props.candidateEdited.email ? props.candidateEdited.email : "",
            high_school: props.candidateEdited && props.candidateEdited.high_school ? props.candidateEdited.high_school : "",
            profile: props.candidateEdited && props.candidateEdited.profile ? props.candidateEdited.profile : "",
            interes_country: props.candidateEdited && props.candidateEdited.interes_country ? props.candidateEdited.interes_country : "",
            specialization: props.candidateEdited && props.candidateEdited.specialization ? props.candidateEdited.specialization : "",
            school_average: props.candidateEdited && props.candidateEdited.school_average ? props.candidateEdited.school_average : "",
            extracurricular_activities: props.candidateEdited && props.candidateEdited.extracurricular_activities ? props.candidateEdited.extracurricular_activities : "",
            city: props.candidateEdited && props.candidateEdited.city ? props.candidateEdited.city : "",
            year_of_admission: props.candidateEdited && props.candidateEdited.year_of_admission ? props.candidateEdited.year_of_admission : "",
            how_find_us: props.candidateEdited && props.candidateEdited.how_find_us ? props.candidateEdited.how_find_us : "",
            // class: props.candidateEdited && props.candidateEdited.class ? props.candidateEdited.class : "",
            contact_date: props.candidateEdited && props.candidateEdited.contact_date ? props.candidateEdited.contact_date : "",
            meet: props.candidateEdited && props.candidateEdited.meet ? props.candidateEdited.meet : "",
            notes: props.candidateEdited && props.candidateEdited.notes ? props.candidateEdited.notes : "",
            phone: props.candidateEdited && props.candidateEdited.phone ? props.candidateEdited.phone : "",
            parent_name: props.candidateEdited && props.candidateEdited.parent_name ? props.candidateEdited.parent_name : "",
            parent_email: props.candidateEdited && props.candidateEdited.parent_email ? props.candidateEdited.parent_email : "",
            parent_phone: props.candidateEdited && props.candidateEdited.parent_phone ? props.candidateEdited.parent_phone : "",
            status: props.candidateEdited && props.candidateEdited.status ? props.candidateEdited.status : "",
            follow_up_1: props.candidateEdited && props.candidateEdited.follow_up_1 ? props.candidateEdited.follow_up_1 : "",
            mentiuni_f1: props.candidateEdited && props.candidateEdited.mentiuni_f1 ? props.candidateEdited.mentiuni_f1 : "",
            follow_up_2: props.candidateEdited && props.candidateEdited.follow_up_2 ? props.candidateEdited.follow_up_2 : "",
            mentiuni_f2: props.candidateEdited && props.candidateEdited.mentiuni_f2 ? props.candidateEdited.mentiuni_f2 : "",
            follow_up_3: props.candidateEdited && props.candidateEdited.follow_up_3 ? props.candidateEdited.follow_up_3 : "",
            mentiuni_f3: props.candidateEdited && props.candidateEdited.mentiuni_f3 ? props.candidateEdited.mentiuni_f3 : "",
            event: props.candidateEdited && props.candidateEdited.event ? props.candidateEdited.event : "",
            meet_date: props.candidateEdited && props.candidateEdited.meet_date ? props.candidateEdited.meet_date : "",
            service_pack: props.candidateEdited && props.candidateEdited.service_pack ? props.candidateEdited.service_pack : "",
        })
    }

    componentWillMount() {
        ReactModal.setAppElement('body');
    }

    handleChange(event) {
        var text = event.target.value;
        var column = event.target.name;

        if(['follow_up_1', 'follow_up_2', 'follow_up_3'].includes(column)) {
            text = moment(new Date(text)).format("DD/MM/yyyy").toString();
        }

        if(!text.replace(/\s/g, '').length) text = '';
        this.setState({[column]: text});
    }

    handleSubmit(event) {
        event.preventDefault();
        var candidate = {
            id: this.state.id,
            firstname: this.state.firstname,
            lastname: this.state.lastname,
            email: this.state.email,
            high_school: this.state.high_school,
            profile: this.state.profile,
            interes_country: this.state.interes_country,
            specialization: this.state.specialization,
            school_average: this.state.school_average,
            extracurricular_activities: this.state.extracurricular_activities,
            city: this.state.city,
            year_of_admission: this.state.year_of_admission,
            how_find_us: this.state.how_find_us,
            // class: this.state.class,
            contact_date: this.state.contact_date,
            meet: this.state.meet,
            notes: this.state.notes,
            phone: this.state.phone,
            parent_name: this.state.parent_name,
            parent_email: this.state.parent_email,
            parent_phone: this.state.parent_phone,
            status: this.state.status,
            follow_up_1: this.state.follow_up_1,
            mentiuni_f1: this.state.mentiuni_f1,
            follow_up_2: this.state.follow_up_2,
            mentiuni_f2: this.state.mentiuni_f2,
            follow_up_3: this.state.follow_up_3,
            mentiuni_f3: this.state.mentiuni_f3,
            event: this.state.event,
            meet_date: this.state.meet_date,
            service_pack: this.state.service_pack,
        }
        this.props.saveCandidate(candidate);
    }

    handleStatusChange(selectedOption) {
        this.setState({status: selectedOption.value});
    };

    handleServicePackChange(selectedOption) {
        this.setState({service_pack: selectedOption.value});
    };

    handleHowFindUsChange(selectedOption) {
        this.setState({how_find_us: selectedOption.value});
    };

    handleYearOfAdmissionChange(selectedOption) {
        this.setState({year_of_admission: selectedOption.value});
    };

    formatDate(value){
        let f1 = value.split("/");
        let date = new Date(f1[2] + '/' + f1[1] + '/' + f1[0]);

        return date;
    }

    renderEditContent(){
        var statusOptions = [
            { value:  'A participat la InfoSession de grup', label: 'A participat la InfoSession de grup' },
            { value: 'Vrea consultanta', label: 'Vrea consultanta' },
            { value: 'Client Existent', label: 'Client Existent' },
            { value: 'A avut intalnire', label: 'A avut intalnire' },
            { value: 'Nu a raspuns', label: 'Nu a raspuns' },
            { value: 'Nu vrea consultanta', label: 'Nu vrea consultanta' },
            { value: 'De facut follow-up', label: 'De facut follow-up' },
            { value: '', label: 'Empty' },
        ];

        var servicePackOptions = [
            { value:  'Partenere EU', label: 'Partenere EU' },
            { value: 'Gold EU', label: 'Gold EU' },
            { value: 'Platinum EU', label: 'Platinum EU' },
            { value: 'Platinum SUA', label: 'Platinum SUA' },
            { value: 'Infoadmitere', label: 'Infoadmitere' },
            { value: '', label: 'Empty' }
        ];

        var howFindUsOptions = [
            { value:  'FB/Insta ADS', label: 'FB/Insta ADS' },
            { value: 'FB/Insta Student Consulting', label: 'FB/Insta Student Consulting' },
            { value: 'Google Ads', label: 'Google Ads' },
            { value: 'Whatsapp', label: 'Whatsapp' },
            { value: 'Pliante', label: 'Pliante' },
            { value: 'Prezentare in liceu', label: 'Prezentare in liceu' },
            { value: 'Recomandare', label: 'Recomandare' },
            { value: 'Profesor', label: 'Profesor' },
            { value: 'Parinte', label: 'Parinte' },
            { value: 'Altele', label: 'Altele' },
            { value: '', label: 'Empty' }
        ];

        var year_of_admission = [
            { value:  '2016', label: '2016' },
            { value:  '2017', label: '2017' },
            { value:  '2018', label: '2018' },
            { value:  '2019', label: '2019' },
            { value:  '2020', label: '2020' },
            { value: '2021', label: '2021' },
            { value: '2022', label: '2022' },
            { value: '2023', label: '2023' },
            { value: '2024', label: '2024' },
            { value: '2025', label: '2025' },
            { value: '2026', label: '2026' },
            { value: '2027', label: '2027' },
            { value: '2028', label: '2028' },
            { value: '', label: 'Empty' }
        ];

        var followUp1Value = moment(Date.parse(this.formatDate(this.state.follow_up_1))).format("yyyy-MM-DD");
        var followUp2Value = moment(Date.parse(this.formatDate(this.state.follow_up_2))).format("yyyy-MM-DD");
        var followUp3Value = moment(Date.parse(this.formatDate(this.state.follow_up_3))).format("yyyy-MM-DD");

        return (
            <div className="edit-container container">
                <div className="modal-title">{ this.props.modalContent == "edit" ? "Editare candidat" : "Creare candidat nou" }</div>
                <div className= "candidate-form">
                    <form onSubmit={this.handleSubmit}>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Nume:</span>
                                <input className="form-control"  name="firstname" type="text" value={ this.state.firstname } onChange={ this.handleChange } />
                            </label>
                            <label className="col-md-6">
                                <span>Prenume:</span>
                                <input name="lastname"  className="form-control" type="text" value={ this.state.lastname } onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Email:</span>
                                <input name="email"  type="email" className="form-control" value={ this.state.email } onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Telefon:</span>
                                <input name="phone"  type="tel" className="form-control" value={this.state.phone} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Nume parinte:</span>
                                <input className="form-control"  name="parent_name" type="text" value={ this.state.parent_name } onChange={ this.handleChange } />
                            </label>
                            <label className="col-md-6">
                                <span>Email parinte:</span>
                                <input name="parent_email"  type="email" className="form-control" value={ this.state.parent_email } onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Telefon parinte:</span>
                                <input name="parent_phone"  type="tel" className="form-control" value={this.state.parent_phone} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Oras:</span>
                                <input name="city" type="text" className="form-control" value={this.state.city} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Liceu:</span>
                                <input name="high_school" type="text" className="form-control" value={this.state.high_school} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Profil:</span>
                                <input name="profile" type="text" className="form-control" value={this.state.profile} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Tara de interes:</span>
                                <input name="interes_country" type="text" className="form-control" value={this.state.interes_country} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Specializare:</span>
                                <input name="specialization" type="text" className="form-control" value={this.state.specialization} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Media 9-11:</span>
                                <input name="school_average" type="text" className="form-control" value={this.state.school_average} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Activitati extrascolare:</span>
                                <input name="extracurricular_activities" type="text" className="form-control" value={this.state.extracurricular_activities} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Eveniment / Office:</span>
                                <input name="event" type="text" className="form-control" value={this.state.event} onChange={this.handleChange} />
                            </label>
                        </div>

                        <div className="row" >
                            <label className="col-md-12 force-black">
                                <span>An admitere:</span>
                                <Select options={year_of_admission}  onChange={this.handleYearOfAdmissionChange} value = { { value: this.state.year_of_admission, label: this.state.year_of_admission} }/>
                            </label>
                        </div>

                        <div className="row" >
                            <label className="col-md-12 force-black">
                                <span>Cum a dat de noi:</span>
                                <Select options={howFindUsOptions}  onChange={this.handleHowFindUsChange} value = { { value: this.state.how_find_us, label: this.state.how_find_us} }/>
                            </label>
                        </div>

                        <div className="row">
                            <label className="col-md-6">
                                <span>Data contactare:</span>
                                <input name="contact_date" className="form-control" type="text" value={this.state.contact_date} onChange={this.handleChange} />
                            </label>

                            <label className="col-md-6">
                                <span>Data meet:</span>
                                <input name="meet_date" type="text" className="form-control" value={this.state.meet_date} onChange={this.handleChange} />
                            </label>

                        </div>

                        <div className="row">
                            <label className="col-md-12">
                                <span>Meet:</span>
                                <input name="meet" type="text" className="form-control" value={this.state.meet} onChange={this.handleChange} />
                            </label>
                        </div>

                        <div className="row">
                            <label className="col-md-12">
                                <span>Mentiuni:</span>
                                <input name="notes" type="textarea" className="form-control" value={this.state.notes} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-12 force-black">
                                <span>Status:</span>
                                <Select options={statusOptions} onChange={this.handleStatusChange} value = { { value: this.state.status, label: this.state.status} }/>
                            </label>
                            <label className="col-md-12 force-black">
                                <span>Pachet servicii:</span>
                                <Select className="text-dark" options={servicePackOptions} onChange={this.handleServicePackChange} value = { { value: this.state.service_pack, label: this.state.service_pack} }/>
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Follow up 1:</span>
                                <input name="follow_up_1" type="date" className="form-control" value={followUp1Value} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Mentiuni f1:</span>
                                <input name="mentiuni_f1" type="text" className="form-control" value={this.state.mentiuni_f1} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Follow up 2:</span>
                                <input name="follow_up_2" type="date" className="form-control" value={followUp2Value} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Mentiuni f2:</span>
                                <input name="mentiuni_f2" type="text" className="form-control" value={this.state.mentiuni_f2} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="row">
                            <label className="col-md-6">
                                <span>Follow up 3:</span>
                                <input name="follow_up_3" type="date" className="form-control" value={followUp3Value} onChange={this.handleChange} />
                            </label>
                            <label className="col-md-6">
                                <span>Mentiuni f3:</span>
                                <input name="mentiuni_f3" type="text" className="form-control" value={this.state.mentiuni_f3} onChange={this.handleChange} />
                            </label>
                        </div>
                        <div className="buttons-container">
                            <input type="submit" value="Salveaza" className="btn btn-success" />
                            {/*<button type="button" className="btn btn-danger" onClick={ this.props.saveCandidate }>Salveaza</button>*/}
                            <button type="button" className="btn btn-light" onClick={ this.props.handleCloseModal }>Renunta</button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }

    renderDeleteContent(){
        return (
            <div className="delete-container">
                <div className="modal-title">Sunteti sigur ca vreti sa stergeti acest candidat?</div>
                <div className="candidate-info">{ this.props.candidateEdited.firstname + " " + this.props.candidateEdited.lastname }</div>
                <div className="buttons-container">
                    <button type="button" className="btn btn-danger" onClick={ this.props.deleteCandidate }>Sterge</button>
                    <button type="button" className="btn btn-light" onClick={ this.props.handleCloseModal }>Renunta</button>
                </div>
            </div>
        );
    }

    render(){
        return (
            <ReactModal
                isOpen={ this.state.showModal }
                style={customStyles}
            >
                <div className="modal-container">
                    <div className="close-button" onClick={ this.props.handleCloseModal }>
                        <button type="button" className="btn-close" aria-label="Close"></button>
                    </div>
                    { this.props.modalContent == "edit" && this.renderEditContent() }
                    { this.props.modalContent == "delete" && this.renderDeleteContent() }
                    { this.props.modalContent == "add" && this.renderEditContent() }
                </div>
            </ReactModal>
        )
    }

}

export default CustomModal;
