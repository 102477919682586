import React from 'react'

import * as FileSaver from 'file-saver';

import * as XLSX from 'xlsx';
import {toast} from "react-hot-toast";



export const ExportData = ({csvData, fileName}) => {



    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    const fileExtension = '.xlsx';


    const exportToCSV = (csvData, fileName) => {

        const ws = XLSX.utils.json_to_sheet(csvData);

        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

        const data = new Blob([excelBuffer], {type: fileType});

        FileSaver.saveAs(data, fileName + fileExtension);

        toast.success('Verifica fisierul',
            {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );

    }



    return (

        <div className="p-2">
            <button className="btn btn-success" onClick={(e) => exportToCSV(csvData,fileName)}>Export Candidati</button>

        </div>

    )

}
