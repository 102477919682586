/**
 * A wraper for the Fetch Api Calls
 *
 * @param      string         action            The action url
 * @param      string         method            The Call type ("POST","GET")
 * @param      object         body              An object that will be sent in the request body
 * @param      Function       successCallback   The success callback
 * @param      Function       errorCallback     The error callback
 * @param      object         resultParams      An object that will be sent along in the callbacks, in case those require extra parameters that were availabe when the call was made
 * @param      object         extraHeaders      An object that will be added in the request headers, in case extra headers are required
 */
export function FetchCall(action, method, body, successCallback = null, errorCallback = null, resultParams = null, extraHeaders = null) {
    var headers = {};

    if (document.getElementById('csrf')) {
        headers = { 'X-CSRF-TOKEN': document.getElementById('csrf').value };
    }

    if (!(body instanceof FormData)) {
        body = JSON.stringify(body);

        headers['Accept'] = 'application/json';
        headers['Content-Type'] = 'application/json';
    }

    if (extraHeaders) {
        headers = Object.assign({}, headers, extraHeaders);
    }

    var requestOptions = {
        method: method,
        credentials: 'include',
        headers: headers,
    }

    if (method == 'POST') {
        requestOptions['body'] = body;
    }

    fetch(action, requestOptions)
        .then(
            function(response) {
                if (response.status !== 200) {
                    if (errorCallback) {
                        response.json().then(function(data) {
                            errorCallback(data, resultParams);
                        });

                        return;
                    }
                    // go on sucess callback if no error callback is set (to accomodate previous behaviour)
                }

                // Examine the text in the response
                response.json().then(function(data) {
                    if (successCallback) {
                        successCallback(data, resultParams);
                    }
                });
            }
        )
        .catch(function(err) {
            console.log('Fetch Error :-S', err);
        });

}
