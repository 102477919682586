import ReactDOM from 'react-dom';
import React from 'react';
import {FetchCall} from "../helpers/http_helper";
import * as XLSX from 'xlsx';
import ReactPaginate from 'react-paginate';
import CustomModal from './customModal';
import {Toaster} from "react-hot-toast";
import {toast} from "react-hot-toast";
import moment from 'moment';
import {ExportData} from './exportData';
import arrowDown from './icons/arrow-down.svg';
import arrowUp from './icons/arrow-up.svg';
import {number} from "tailwindcss/lib/util/dataTypes";
import Select from "react-select";

const perPage = 30;

class Candidates extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authUserRole: 0,
            followUpToday: 0,
            followUps: false,
            candidatesUploaded: [],
            candidates: [],
            candidatesToExport: [],
            nrOfPages: 0,
            currentPage: 0,
            totalCandidates: 0,
            offset: 0,
            loading: true,
            showModal: false,
            modalContent: null,
            candidateEdited: null,
            search_firstname: "",
            search_lastname: "",
            search_email: "",
            search_high_school: "",
            search_profile: "",
            search_interes_country: "",
            search_specialization: "",
            search_school_average: "",
            search_extracurricular_activities: "",
            search_city: "",
            search_year_of_admission: "",
            search_how_find_us: "",
            search_contact_date: "",
            search_meet: "",
            search_notes: "",
            search_phone: "",
            search_parent_name: "",
            search_parent_email: "",
            search_parent_phone: "",
            search_status: "",
            search_follow_up_1: "",
            search_mentiuni_f1: "",
            search_follow_up_2: "",
            search_mentiuni_f2: "",
            search_follow_up_3: "",
            search_mentiuni_f3: "",
            search_event: "",
            search_meet_date: "",
            search_service_pack: "",
            resetField: "",
            sort: {},
        }

        this.handlePageClick = this.handlePageClick.bind(this);
        this.handleCloseModal = this.handleCloseModal.bind(this);
        this.deleteCandidate = this.deleteCandidate.bind(this);
        this.saveCandidate = this.saveCandidate.bind(this);
        this.handleSearchHowFindUs = this.handleSearchHowFindUs.bind(this);
        this.handleSearchStatus = this.handleSearchStatus.bind(this);
        this.handleSearchServicePack = this.handleSearchServicePack.bind(this);
        this.handleSearchYearOfAdmission = this.handleSearchYearOfAdmission.bind(this);
        this.handleSortChange = this.handleSortChange.bind(this);
    }

    componentDidMount() {
        var data = this.getDefaultParams();
        data['followUps'] = false;
        FetchCall('/getCandidates', "POST", { data }, this.getCandidatesSuccess.bind(this));
    }

    getDefaultParams(){
        return {
            offset: this.state.currentPage * perPage,
            sort: this.state.sort,
            followUps: this.state.followUps,
            search_firstname: this.state.search_firstname,
            search_lastname: this.state.search_lastname,
            search_email: this.state.search_email,
            search_high_school: this.state.search_high_school,
            search_profile: this.state.search_profile,
            search_interes_country: this.state.search_interes_country,
            search_specialization: this.state.search_specialization,
            search_school_average: this.state.search_school_average,
            search_extracurricular_activities: this.state.search_extracurricular_activities,
            search_city: this.state.search_city,
            search_year_of_admission: this.state.search_year_of_admission,
            search_how_find_us: this.state.search_how_find_us,
            search_contact_date: this.state.search_contact_date,
            search_meet: this.state.search_meet,
            search_notes: this.state.search_notes,
            search_phone: this.state.search_phone,
            search_parent_name: this.state.search_parent_name,
            search_parent_email: this.state.search_parent_email,
            search_parent_phone: this.state.search_parent_phone,
            search_status: this.state.search_status,
            search_follow_up_1: this.state.search_follow_up_1,
            search_mentiuni_f1: this.state.search_mentiuni_f1,
            search_follow_up_2: this.state.search_follow_up_2,
            search_mentiuni_f2: this.state.search_mentiuni_f2,
            search_follow_up_3: this.state.search_follow_up_3,
            search_mentiuni_f3: this.state.search_mentiuni_f3,
            search_event: this.state.search_event,
            search_meet_date: this.state.search_meet_date,
            search_service_pack: this.state.search_service_pack,
        }
    }

    getCandidatesSuccess(result){
        this.setState({
            resetField: "",
            candidates: result.response.candidates,
            followUpToday: result.response.followUpToday,
            followUps: result.response.followUps,
            nrOfPages: result.response.nr_of_pages,
            totalCandidates: result.response.total_candidates,
            loading: false,
            authUserRole: result.response.user_role,
            candidatesToExport: result.response.candidates_to_export
        });
    }

    readExcel(file){
            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(file);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;

                    const wb = XLSX.read(bufferArray, { type: "buffer" });

                    const wsname = wb.SheetNames[0];

                    const ws = wb.Sheets[wsname];

                    const data = XLSX.utils.sheet_to_json(ws);

                    resolve(data);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };
            });

            promise.then((d) => {
                this.setState({candidatesUploaded: d});
            });
    }

    importData(){
        var data = this.getDefaultParams();
        data['candidatesUploaded'] = this.state.candidatesUploaded;

        FetchCall('/upload/candidates', "POST",  { data } , this.getCandidatesSuccess.bind(this));

        if (this.state.resetField != "") {
            toast.success('Datele se incarca. Ai rabdare.',
                {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        }
        else {
            toast.error('Nu ai introdus nimic',
                {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        }

    }

    deleteData(){
        var data = this.getDefaultParams();

        if (confirm('Atentie, vei sterge toate datele.')){
            FetchCall('/deleteAllData', "POST", {data}, this.getCandidatesSuccess.bind(this));
            toast.success('Datele au fost sterse cu success',
                {
                    style: {
                        borderRadius: '10px',
                        background: '#333',
                        color: '#fff',
                    },
                }
            );
        }
    }

    handlePageClick(page) {
        this.setState({offset: page.selected * perPage, currentPage: page.selected}, () => this.getDataAfterChangePage());
    }

    getDataAfterChangePage() {
        var data = this.getDefaultParams();
        FetchCall('/getCandidates', "POST", {data}, this.getCandidatesSuccess.bind(this));
    }

    handleSearch(e){
        var text = e.target.value;
        var column = "search_" + e.target.name;

        if(!text.replace(/\s/g, '').length) text = '';
        this.setState({[column]: text, loading: true}, () => this.handleSearchChange());
    }

    handleSearchHowFindUs(selectedOption) {
        this.setState({search_how_find_us: selectedOption.value, loading: true}, () => this.handleSearchChange());
    }

    handleSearchStatus(selectedOption) {
        this.setState({search_status: selectedOption.value, loading: true}, () => this.handleSearchChange());
    }

    handleSearchServicePack(selectedOption) {
        this.setState({search_service_pack: selectedOption.value, loading: true}, () => this.handleSearchChange());
    }

    handleSearchYearOfAdmission(selectedOption) {
        this.setState({search_year_of_admission: selectedOption.value, loading: true}, () => this.handleSearchChange());
    }

    handleSearchChange(){
        clearTimeout(this.searchTypingTimer);
        this.searchTypingTimer = setTimeout(function() {
            this.searchByColumn();
        }.bind(this), 20);
    }

    handleSortChange(value){
        this.setState({sort: value}, () => this.getDataAfterSort());
    }

    searchByColumn(){
        var data = this.getDefaultParams();
        data['offset'] = 0;
        FetchCall('/getCandidates', "POST", {data}, this.getCandidatesSuccess.bind(this));
    }

    openModal(action, candidate){
        this.setState({showModal: true, modalContent: action, candidateEdited: candidate});
    }

    handleCloseModal(){
        this.setState({showModal: false});
    }

    deleteCandidate(){
        var data = this.getDefaultParams();
        data['candidateId'] = this.state.candidateEdited.id;

        FetchCall('/deleteCandidate', "POST", {data}, this.getCandidatesSuccess.bind(this));
        this.setState({showModal: false});
        toast.success('Am sters cu success intrarea',
            {
                style: {
                    borderRadius: '10px',
                    background: '#333',
                    color: '#fff',
                },
            }
        );
    }

    saveCandidate(candidate){
        var data = this.getDefaultParams();
        data['candidate'] = candidate;

        FetchCall('/updateCandidate', "POST", {data}, this.getCandidatesSuccess.bind(this));
        this.setState({showModal: false});
    }

    getDataAfterSort(){
        var data = this.getDefaultParams();
        FetchCall('/getCandidates', "POST", {data}, this.getCandidatesSuccess.bind(this));
    }

    showFollowUps(){
        this.setState({followUps: true}, () => this.getFollowUps());
    }

    handleResetFilters() {
        this.setState({
            search_firstname: "",
            search_lastname: "",
            search_email: "",
            search_high_school: "",
            search_profile: "",
            search_interes_country: "",
            search_specialization: "",
            search_school_average: "",
            search_extracurricular_activities: "",
            search_city: "",
            search_year_of_admission: "",
            search_how_find_us: "",
            search_contact_date: "",
            search_meet: "",
            search_notes: "",
            search_phone: "",
            search_parent_name: "",
            search_parent_email: "",
            search_parent_phone: "",
            search_status: "",
            search_follow_up_1: "",
            search_mentiuni_f1: "",
            search_follow_up_2: "",
            search_mentiuni_f2: "",
            search_follow_up_3: "",
            search_mentiuni_f3: "",
            search_event: "",
            search_meet_date: "",
            search_service_pack: "",
            followUps: false

        }, () => this.getDataAfterResetfilters());
    }

    getDataAfterResetfilters(){
        var data = this.getDefaultParams();
        FetchCall('/getCandidates', "POST", {data}, this.getCandidatesSuccess.bind(this));
    }

    getFollowUps(){
        var data = this.getDefaultParams();
        FetchCall('/getCandidates', "POST", { data }, this.getCandidatesSuccess.bind(this));
    }

    render() {
        var howFindUsOptions = [
            { value:  'FB/Insta ADS', label: 'FB/Insta ADS' },
            { value: 'FB/Insta Student Consulting', label: 'FB/Insta Student Consulting' },
            { value: 'Google Ads', label: 'Google Ads' },
            { value: 'Whatsapp', label: 'Whatsapp' },
            { value: 'Pliante', label: 'Pliante' },
            { value: 'Prezentare in liceu', label: 'Prezentare in liceu' },
            { value: 'Recomandare', label: 'Recomandare' },
            { value: 'Profesor', label: 'Profesor' },
            { value: 'Parinte', label: 'Parinte' },
            { value: 'Altele', label: 'Altele' },
            { value: '', label: 'Empty' },
        ];

        var statusOptions = [
            { value:  'A participat la InfoSession de grup', label: 'A participat la InfoSession de grup' },
            { value: 'Vrea consultanta', label: 'Vrea consultanta' },
            { value: 'Client Existent', label: 'Client Existent' },
            { value: 'A avut intalnire', label: 'A avut intalnire' },
            { value: 'Nu a raspuns', label: 'Nu a raspuns' },
            { value: 'Nu vrea consultanta', label: 'Nu vrea consultanta' },
            { value: 'De facut follow-up', label: 'De facut follow-up' },
            { value: '', label: 'Empty' },
        ];

        var servicePackOptions = [
            { value:  'Partenere EU', label: 'Partenere EU' },
            { value: 'Gold EU', label: 'Gold EU' },
            { value: 'Platinum EU', label: 'Platinum EU' },
            { value: 'Platinum SUA', label: 'Platinum SUA' },
            { value: 'Infoadmitere', label: 'Infoadmitere' },
            { value: '', label: 'Empty' }
        ];

        var year_of_admission = [
            { value:  '2016', label: '2016' },
            { value:  '2017', label: '2017' },
            { value:  '2018', label: '2018' },
            { value:  '2019', label: '2019' },
            { value:  '2020', label: '2020' },
            { value: '2021', label: '2021' },
            { value: '2022', label: '2022' },
            { value: '2023', label: '2023' },
            { value: '2024', label: '2024' },
            { value: '2025', label: '2025' },
            { value: '2026', label: '2026' },
            { value: '2027', label: '2027' },
            { value: '2028', label: '2028' },
            { value: '', label: 'Empty' }
        ];

        var sortOptions = [
            { value: '1', label: "Nume - A-Z"},
            { value: '2', label: "Nume - Z-A"},
            { value: '3', label: "Data de creare A-Z"},
            { value: '4', label: "Data de creare - Z-A"},
            { value: '', label: "Empty"},
        ]

        return (
            <div className="candidates-container">
                <Toaster
                    position="top-center"
                    reverseOrder={false}
                />

                { this.state.authUserRole == 1 && <div className="upload-content">
                    <div className="d-flex flex-column my-3 px-2">
                        <div className="w-100 mx-auto text-center d-flex justify-content-between align-content-center">
                            <div className="bg-white p-2 rounded">


                                <input type="file" value={this.state.resetField} onChange={ (e) => {
                                    const file = e.target.files[0];
                                    this.readExcel(file);
                                    this.setState({resetField: e.target.value});
                                }}/>

                                <button type="button" className="btn btn-primary" onClick={() => this.importData()}>
                                    Import data
                                </button>
                            </div>
                            <div className="d-flex">
                                <div className="p-2">
                                    <button type="button" className="btn btn-light" onClick={ () => this.handleResetFilters()}>Reset filtre</button>
                                </div>
                                { this.state.authUserRole == 1 && <div className="p-2">
                                    <button type="button" className="btn btn-primary" onClick={ () => this.openModal("add", null)}>+ Add</button>
                                </div> }
                                { this.state.authUserRole == 1 && <div className="p-2">
                                    <button type="button" className="btn btn-info" onClick={ () => this.showFollowUps()}>{this.state.followUpToday + " Follow Up Today"}</button>
                                </div> }
                                <ExportData csvData={ this.state.candidatesToExport } fileName="Export Candidati" />
                                { this.state.authUserRole == 1 && <div className="p-2">
                                    <button type="button" className="btn btn-danger" onClick={ () => this.deleteData()}>Delete data</button>
                                </div> }
                            </div>
                        </div>
                    </div>


                </div>}
                <div className="candidates-content">
                    <div className="sort-container">
                        <span>Sort by:</span>
                        <div className="sort-dropdown">
                            <Select options={sortOptions} name="sort" onChange={this.handleSortChange} value = { this.state.sort }/>
                        </div>
                    </div>
                    <div className="table table-responsive table-height-custom">
                        {this.state.loading && <div className="loading"></div>}
                        <table className="table table-striped" id="candidates-table">
                            <tbody>
                            <tr>
                                <th className="font-size-14">#</th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span onClick={() => this.toggleSortDataByName()}>Nume</span>
                                        <input type="text" className="form-control" name="firstname" placeholder="Search..." value={ this.state.search_firstname } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Prenume</span>
                                        <input type="text" className="form-control" name="lastname" placeholder="Search..." value={ this.state.search_lastname } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </ th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Email</span>
                                        <input type="text" className="form-control" name="email" placeholder="Search..." value={ this.state.search_email } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Telefon</span>
                                        <input type="text" className="form-control" name="phone" placeholder="Search..." value={ this.state.search_phone } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Nume parinte</span>
                                        <input type="text" className="form-control" name="parent_name" placeholder="Search..." value={ this.state.search_parent_name } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Email parinte</span>
                                        <input type="text" className="form-control" name="parent_email" placeholder="Search..." value={ this.state.search_parent_email } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Telefon parinte</span>
                                        <input type="text" className="form-control" name="parent_phone" placeholder="Search..." value={ this.state.search_parent_phone } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Liceu</span>
                                        <input type="text" className="form-control" name="high_school" placeholder="Search..." value={ this.state.search_high_school } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Profil</span>
                                        <input type="text" className="form-control" name="profile" placeholder="Search..." value={ this.state.search_profile } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Tara de interes</span>
                                        <input type="text" className="form-control" name="interes_country" placeholder="Search..." value={ this.state.search_interes_country } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Specializare</span>
                                        <input type="text" className="form-control" name="specialization" placeholder="Search..." value={ this.state.search_specialization } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Media 9-11</span>
                                        <input type="text" className="form-control" name="school_average" placeholder="Search..." value={ this.state.search_school_average } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Activitati extrascolare</span>
                                        <input type="text" className="form-control" name="extracurricular_activities" placeholder="Search..." value={ this.state.search_extracurricular_activities } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Oras</span>
                                        <input type="text" className="form-control" name="city" placeholder="Search..." value={ this.state.search_city } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>An admitere</span>
                                        <div className="force-black">
                                            <Select options={year_of_admission} name="year_of_admission" onChange={this.handleSearchYearOfAdmission} value = { { value: this.state.search_year_of_admission, label: this.state.search_year_of_admission} }/>
                                        </div>
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Eveniment / Office</span>
                                        <input type="text" className="form-control" name="event" placeholder="Search..." value={ this.state.search_event } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <span>Cum a dat de noi</span>
                                    <div className="force-black">
                                        <Select options={howFindUsOptions} name="how_find_us" onChange={this.handleSearchHowFindUs} value = { { value: this.state.search_how_find_us, label: this.state.search_how_find_us} }/>
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Data contactare</span>
                                        <input type="text" className="form-control" name="contact_date" placeholder="Search..." value={ this.state.search_contact_date } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Meet</span>
                                        <input type="text" className="form-control" name="meet" placeholder="Search..." value={ this.state.search_meet } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Data meet</span>
                                        <input type="text" className="form-control" name="meet_date" placeholder="Search..." value={ this.state.search_meet_date } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <span>Status</span>
                                    <div className="force-black">
                                        <Select options={statusOptions} onChange={this.handleSearchStatus} value = { { value: this.state.search_status, label: this.state.search_status} }/>
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <div>
                                        <span>Mentiuni</span>
                                        <input type="text" className="form-control" name="notes" placeholder="Search..." value={ this.state.search_notes } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "200px"}}>
                                    <span>Pachet servicii</span>
                                    <div className="force-black">
                                        <Select options={servicePackOptions} onChange={this.handleSearchServicePack} value = { { value: this.state.search_service_pack, label: this.state.search_service_pack} }/>
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Follow up 1</span>
                                        <input type="text" className="form-control" name="follow_up_1" placeholder="Search..." value={ this.state.search_follow_up_1 } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Mentiuni f1</span>
                                        <input type="text" className="form-control" name="mentiuni_f1" placeholder="Search..." value={ this.state.search_mentiuni_f1 } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Follow up 2</span>
                                        <input type="text" className="form-control" name="follow_up_2" placeholder="Search..." value={ this.state.search_follow_up_2 } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Mentiuni f2</span>
                                        <input type="text" className="form-control" name="mentiuni_f2" placeholder="Search..." value={ this.state.search_mentiuni_f2 } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Follow up 3</span>
                                        <input type="text" className="form-control" name="follow_up_3" placeholder="Search..." value={ this.state.search_follow_up_3 } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <div>
                                        <span>Mentiuni f3</span>
                                        <input type="text" className="form-control" name="mentiuni_f3" placeholder="Search..." value={ this.state.search_mentiuni_f3 } onChange={(e) => this.handleSearch(e)} />
                                    </div>
                                </th>
                                <th className="font-size-14" style={{width: "100px"}}>
                                    <span>Data creare</span>
                                </th>
                                { this.state.authUserRole == 1 && <th className="font-size-14"></th>}
                            </tr>

                            {this.state.candidates.map((candidate, i) => {
                                return (
                                    <tr key = {i}>

                                        <td className="font-size-14">{this.state.offset + i + 1}</td>
                                        <td style={{width: "100px"}} className="font-size-14">{candidate.firstname}</td>
                                        <td style={{width: "100px"}}className="font-size-14">{candidate.lastname}</td>
                                        <td style={{width: "100px"}} className="font-size-14" >{candidate.email}</td>
                                        <td className="font-size-14">{candidate.phone}</td>
                                        <td className="font-size-14">{candidate.parent_name}</td>
                                        <td className="font-size-14">{candidate.parent_email}</td>
                                        <td className="font-size-14">{candidate.parent_phone}</td>
                                        <td style={{width: "200px"}} className="font-size-14">{candidate.high_school}</td>
                                        <td className="font-size-14">{candidate.profile}</td>
                                        <td className="font-size-14">{candidate.interes_country}</td>
                                        <td className="font-size-14">{candidate.specialization}</td>
                                        <td className="font-size-14">{candidate.school_average}</td>
                                        <td className="font-size-14">{candidate.extracurricular_activities}</td>
                                        <td className="font-size-14">{candidate.city}</td>
                                        <td className="font-size-14">{candidate.year_of_admission}</td>
                                        <td className="font-size-14">{candidate.event}</td>
                                        <td className="font-size-14">{candidate.how_find_us}</td>
                                        <td className="font-size-14">{candidate.contact_date}</td>
                                        <td className="font-size-14">{candidate.meet}</td>
                                        <td className="font-size-14">{candidate.meet_date}</td>
                                        <td className="font-size-14">{candidate.status}</td>
                                        <td className="font-size-14">{candidate.notes}</td>
                                        <td className="font-size-14">{candidate.service_pack}</td>
                                        <td className="font-size-14">{candidate.follow_up_1}</td>
                                        <td className="font-size-14">{candidate.mentiuni_f1}</td>
                                        <td className="font-size-14">{candidate.follow_up_2}</td>
                                        <td className="font-size-14">{candidate.mentiuni_f2}</td>
                                        <td className="font-size-14">{candidate.follow_up_3}</td>
                                        <td className="font-size-14">{candidate.mentiuni_f3}</td>
                                        <td className="font-size-14">{ moment(candidate.created_at).format("DD/MM/yyyy") }</td>
                                        {this.state.authUserRole == 1 && <td className="font-size-14">
                                            <div className="action-buttons">
                                                <span className="edit-button" onClick={ () => this.openModal("edit", candidate) }>Edit</span>
                                                <span className="delete-button" onClick={ () => this.openModal("delete", candidate) }>Delete</span>
                                            </div>
                                        </td>}
                                    </tr>
                                );
                            })}
                            </tbody>
                        </table>

                    </div>
                    { !this.state.loading && this.state.candidates.length == 0 && <div className="nothing-found p-2 mt-5">Nu s-a gasit niciun rezultat. Incarcati un fisier excel.</div> }
                    {this.state.totalCandidates > perPage && <ReactPaginate
                        previousLabel={'<<'}
                        nextLabel={'>>'}
                        breakLabel={'...'}
                        breakClassName={'break-me'}
                        pageCount={this.state.nrOfPages}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={this.handlePageClick}
                        containerClassName={'filters__pagination'}
                        subContainerClassName={'pages pagination'}
                        activeClassName={'active'}
                    />}
                </div>
                <CustomModal
                    showModal = { this.state.showModal }
                    handleCloseModal = { this.handleCloseModal }
                    modalContent = { this.state.modalContent }
                    candidateEdited = { this.state.candidateEdited }
                    deleteCandidate = { this.deleteCandidate }
                    saveCandidate = { this.saveCandidate }
                />
            </div>
        )
    }
}

export default Candidates;

if (document.getElementById('candidates-react')) {
    ReactDOM.render(<Candidates />, document.getElementById('candidates-react'));
}
